import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Page } from './constant/Page';
import Account from './page/account/Account';
import AddressManagement from './page/addressManagement/AddressManagement';
import AgentRebateAdmin from './page/agentRebateAdmin/AgentRebateAdmin';
import AnnouncementAdmin from './page/announcementAdmin/AnnouncementAdmin';
import AnnouncementAdminDetail from './page/announcementAdminDetail/AnnouncementAdminDetail';
import AnnouncementEdit from './page/announcementEdit/AnnouncementEdit';
import AskerLimit from './page/askerLimit/AskerLimit';
import ForgetPassword from './page/authForgetPassword/ForgetPassword';
import SignIn from './page/authSignIn/SignIn';
import SignOut from './page/authSignOut/SignOut';
import BalanceAdmin from './page/balanceAdmin/BalanceAdmin';
import BalanceStatisticsAdmin from './page/balanceStatisticsAdmin/BalanceStatisticsAdmin';
import BankAdmin from './page/bankAdmin/BankAdmin';
import CommissionAdmin from './page/commissionAdmin/CommissionAdmin';
import CommissionAdminDetail from './page/commissionAdminDetail/CommissionAdminDetail';
import Deposit from './page/deposit/Deposit';
import DepositAdmin from './page/depositAdmin/DepositAdmin';
import DepositAdminDetail from './page/depositAdminDetail/DepositAdminDetail';
import EnableTfa from './page/enableTfa/EnableTfa';
import FillPage from './page/fill/Fill';
import FillDetail from './page/fillDetail/FillDetail';
import Frozen from './page/frozen/Frozen';
import Home from './page/home/Home';
import MessageAdmin from './page/messageAdmin/MessageAdmin';
import MessageCannedAdmin from './page/messageCannedAdmin/MessageCannedAdmin';
import OrderAdmin from './page/orderAdmin/OrderAdmin';
import OrderAdminDetail from './page/orderAdminDetail/OrderAdminDetail';
import ParentRebateAdmin from './page/parentRebateAdmin/ParentRebateAdmin';
import Profit from './page/profit/Profit';
import RebateAdminDetail from './page/rebateAdminDetail/RebateAdminDetail';
import Setting from './page/setting/Setting';
import SystemProperty from './page/systemProperty/SystemProperty';
import TradeAdmin from './page/tradeAdmin/TradeAdmin';
import TradeAdminDetail from './page/tradeAdminDetail/TradeAdminDetail';
import User from './page/user/User';
import UserDetail from './page/userDetail/UserDetail';
import UserProperty from './page/userProperty/UserProperty';
import Withdrawal from './page/withdrawal/Withdrawal';
import WithdrawalAdmin from './page/withdrawalAdmin/WithdrawalAdmin';
import WithdrawalAdminDetail from './page/withdrawalAdminDetail/WithdrawalAdminDetail';
import WithdrawalProfit from './page/withdrawalProfit/WithdrawalProfit';
import WithdrawalSuccess from './page/withdrawalSuccess/WithdrawalSuccess';
import { RootState } from './redux/store';

const AppRoutes = () => {
  const { isLogin } = useSelector((state: RootState) => state.auth);
  const location = useLocation();

  return (
    <Routes>
      {!isLogin && (
        <>
          <Route path={Page.SignIn} element={<SignIn />} />
          <Route path={Page.ForgetPassword} element={<ForgetPassword />} />
          <Route
            path="*"
            element={<Navigate to={Page.SignIn} state={{ from: location.pathname }} />}
          />
        </>
      )}
      {isLogin && (
        <>
          <Route path={Page.SignOut} element={<SignOut />} />
          <Route path={Page.UserAsk} element={<User />} />
          <Route path={`${Page.UserAsk}/:id`} element={<UserDetail />} />
          <Route path={`${Page.UserAsk}/:id/property/:coin`} element={<UserProperty />} />
          <Route path={`${Page.UserAsk}/deposit`} element={<Deposit />} />
          <Route path={Page.UserBid} element={<User />} />
          <Route path={`${Page.UserBid}/:id`} element={<UserDetail />} />
          <Route path={`${Page.UserBid}/:id/property/:coin`} element={<UserProperty />} />
          <Route path={`${Page.UserBid}/deposit`} element={<Deposit />} />
          <Route path={Page.UserAdmin} element={<User />} />
          <Route path={`${Page.UserAdmin}/:id`} element={<UserDetail />} />
          <Route path={`${Page.UserAdmin}/:id/property/:coin`} element={<UserProperty />} />
          <Route path={`${Page.UserAdmin}/deposit`} element={<Deposit />} />
          <Route path={`${Page.UserAdmin}/withdrawal`} element={<Withdrawal />} />
          <Route path={`${Page.UserAdmin}/withdrawal/management`} element={<AddressManagement />} />
          <Route path={`${Page.UserAdmin}/withdrawal/success`} element={<WithdrawalSuccess />} />
          <Route path={Page.Account} element={<Account />} />
          <Route path={Page.Setting} element={<Setting />} />
          <Route path={Page.EnableTfa} element={<EnableTfa />} />
          <Route path={Page.Home} element={<Home />} />
          <Route path={`${Page.Home}/:coin/profit`} element={<Profit />} />
          <Route path={`${Page.Home}/profit/withdrawal`} element={<WithdrawalProfit />} />
          <Route path={`${Page.Home}/:coin/property`} element={<SystemProperty />} />
          <Route path={`${Page.Home}/:coin/frozen`} element={<Frozen />} />
          <Route path={Page.Fill} element={<FillPage />} />
          <Route path={`${Page.Fill}/:id`} element={<FillDetail />} />
          <Route path={Page.Trade} element={<TradeAdmin />} />
          <Route path={`${Page.Trade}/:id`} element={<TradeAdminDetail />} />
          <Route path={Page.Order} element={<OrderAdmin />} />
          <Route path={`${Page.Order}/:id`} element={<OrderAdminDetail />} />
          <Route path={Page.Balance} element={<BalanceAdmin />} />
          <Route path={Page.BalanceStatistics} element={<BalanceStatisticsAdmin />} />
          <Route path={Page.DepositRecord} element={<DepositAdmin />} />
          <Route path={`${Page.DepositRecord}/:id`} element={<DepositAdminDetail />} />
          <Route path={Page.WithdrawalRecord} element={<WithdrawalAdmin />} />
          <Route path={`${Page.WithdrawalRecord}/:id`} element={<WithdrawalAdminDetail />} />
          <Route path={Page.Commission} element={<CommissionAdmin />} />
          <Route path={`${Page.Commission}/:id`} element={<CommissionAdminDetail />} />
          <Route path={Page.Announcement} element={<AnnouncementAdmin />} />
          <Route path={`${Page.Announcement}/:id`} element={<AnnouncementAdminDetail />} />
          <Route path={`${Page.Announcement}/edit`} element={<AnnouncementEdit />} />
          <Route path={Page.ParentRebate} element={<ParentRebateAdmin />} />
          <Route path={`${Page.ParentRebate}/:id`} element={<RebateAdminDetail />} />
          <Route path={Page.AgentRebate} element={<AgentRebateAdmin />} />
          <Route path={`${Page.AgentRebate}/:id`} element={<RebateAdminDetail />} />
          <Route path={Page.Bank} element={<BankAdmin />} />
          <Route path={Page.Message} element={<MessageAdmin />} />
          <Route path={Page.MessageCanned} element={<MessageCannedAdmin />} />
          <Route path={Page.AskerLimit} element={<AskerLimit />} />
          <Route path="*" element={<Navigate to={Page.Home} />} />
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;
