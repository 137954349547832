import balanceSnapshotEndpoint from 'src/api/balanceSnapshotEndpoint';
import { GetBalanceSnapshotListParams } from 'src/model/Api';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting, updateFilter } from 'src/redux/uiSlice';
import { getPage } from 'src/util/paginationHelper';
import { toCsvFile } from 'src/util/toCsv';

export const getBalancesStatistics = async (params?: GetBalanceSnapshotListParams, csv = false) => {
  try {
    dispatch(startWaiting());
    const res = await balanceSnapshotEndpoint.getBalanceSnapshotList(
      {
        coin: 'usdt',
        ...params,
      },
      csv ? { Accept: 'text/csv' } : undefined,
    );

    dispatch(
      updateFilter({
        key: 'adminBalanceSnapshot',
        filter: {
          id: params?.email,
          sorting: params?.sort,
          begin: params?.begin,
          end: params?.end,
          limit: params?.limit,
          page: getPage(params?.offset, params?.limit),
        },
      }),
    );

    if (csv) toCsvFile('balance.csv', res.data as unknown as string);

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};
