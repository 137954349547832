import { GetBalancesListParams, GetBalanceSnapshotListResponse } from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getBalanceSnapshotList = async (
  params: GetBalancesListParams,
  headers?: { [key: string]: string },
) => {
  try {
    return await http.authGet<GetBalanceSnapshotListResponse>('balance-snapshots', {
      params,
      headers,
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  getBalanceSnapshotList,
};
